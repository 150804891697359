import React, { useState, useEffect, useContext, createContext, useRef } from 'react';
import { DynamoDB, S3 } from 'aws-sdk'; // Use the correct import for your AWS SDK version
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faPlay} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


// Initialize DynamoDB
const dynamodb = new DynamoDB.DocumentClient({
  region: 'us-east-1',
  // Replace these with your own AWS credentials
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new S3({
  region: 'us-east-1',
  // Replace these with your own AWS credentials
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export function RetrieveSharedAudios() {
  const [items, setItems] = useState([]);
  const audioRefs = useRef([]);
  const [currentTime, setCurrentTime] = useState(0);
  const navigate = useNavigate(); // Use useNavigate for redirection

  const storedUser = localStorage.getItem('user');
  const userObject = JSON.parse(storedUser);
  const email = userObject.name;

  const { id } = useParams();
  const playlistID = id;
  
  
  
  const fetchItemsByUserEmail = async () => {
    try {
      const paramCheck = {
        TableName: 'MAP-sharedList',
        IndexName: 'sharedUsers-playlistID-index',
        KeyConditionExpression: 'sharedUsers = :email AND playlistID = :playlistid',
        ExpressionAttributeValues: {
          ':email': email,
          ':playlistid': playlistID,
          ':approvedValue': 'yes',
        },
        FilterExpression: 'approved = :approvedValue',
      };
  
      const results = await dynamodb.query(paramCheck).promise();
      console.log(results.Items.length);
      if (results.Items.length > 0) {
        const isShared = results.Items[0].isShared;
        const approved = results.Items[0].approved;
        if (isShared === 'Yes' && approved === 'yes') {
          const params = {
            TableName: 'MAP-audios',
            IndexName: 'playlistID-index',
            KeyConditionExpression: 'playlistID = :playlist',
            ExpressionAttributeValues: {
              ':playlist': playlistID,
            },
          };
  
          const audioResults = await dynamodb.query(params).promise();
  
          const audioItems = audioResults.Items.map((item) => ({
            audioID: item.audioID,
            audioTitle: item.audioTitle,
            audioTags: item.audioTags,
            s3Link: item.s3Link,
            // Add more attributes as needed
          }));
  
          setItems(audioItems);
        } else {
          
        }
      } else {
        navigate('/notfound');
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };
  

  useEffect(() => {
    fetchItemsByUserEmail();
  }, []);
  

  const [activeAudioIndex, setActiveAudioIndex] = useState(null);

  const handleAudioEnded = (index) => {
    if (audioRefs.current[index]) {
      audioRefs.current[index].currentTime = 0; // Reset playback to the beginning
      setCurrentTime(0);
      setActiveAudioIndex(null); // Hide the player
    }
  };

  const handleTimeUpdate = (index) => {
    if (audioRefs.current[index] && currentTime > 20) {
      audioRefs.current[index].pause(); // Pause the audio after 20 seconds
      setCurrentTime(0);
      setActiveAudioIndex(null); // Hide the player
    }
  };

  const toggleAudioPlayer = (index) => {
    if (activeAudioIndex === index) {
      setActiveAudioIndex(null); // Hide the player if it's already open
    } else {
      setActiveAudioIndex(index); // Show the player for the clicked audio
      if (audioRefs.current[index]) {
        audioRefs.current[index].currentTime = 0; // Reset playback to the beginning
        setCurrentTime(0);
        audioRefs.current[index].play(); // Start playback
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Check the current time of the active audio every second
      if (activeAudioIndex !== null && audioRefs.current[activeAudioIndex]) {
        setCurrentTime(audioRefs.current[activeAudioIndex].currentTime);
        if (currentTime > 20) {
          audioRefs.current[activeAudioIndex].pause();
          setCurrentTime(0);
          setActiveAudioIndex(null);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [activeAudioIndex, currentTime]);

 
  

  return (
    <>
      <div className="title-page"><h1><span>AUDIOS IN THIS SHARED PLAYLIST</span>
        </h1>
        <p>In Alexa, you can play this shared playlist by saying "Alexa, tell My Audio to play "[PLAYLIST NAME"]. You can't play
          audios directly from shared playlists, only playlist owners. Check out how to use the <Link to='/how'>Alexa Commands</Link>.
        </p>
        </div>
         <div className="app-block">
      <div>
        {items.map((AudioItem, index) => (
          <div key={index} className="audio-set">
            <a
              className="audio-title"
              onClick={() => toggleAudioPlayer(index)}
              style={{
                color: activeAudioIndex === index ? 'yellow' : 'white',
              }}
            >
              {AudioItem.audioTitle}  <FontAwesomeIcon icon={faPlay} style={{ marginRight: '5px' }} />
            </a>
            <p className="audio-tags-shared">
              {AudioItem.audioTags.split(',').map((tag, index) => (
                <span key={index} className="audio-tag">
                  [ {tag.trim()} ]
                </span>
              ))}
            </p>
            <audio
            ref={(ref) => (audioRefs.current[index] = ref)}
            controls
            className={activeAudioIndex === index ? 'audioactive' : 'audionone'}
            controlsList="nodownload"
            onEnded={() => handleAudioEnded(index)}
            onTimeUpdate={() => handleTimeUpdate(index)}
          >
            <source src={AudioItem.s3Link} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};