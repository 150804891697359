import { AuthData } from "../../auth/AuthWrapper";
import { Stripe } from 'stripe';

const stripe = new Stripe('sk_test_51O22HdFrfuuBIT4LYW35P7fHidjRRTjFckBOhFxRG4a49N4vOLz9U3Xfa7bb3wSfvjgTNODUdQFKK2qeJ2DeygvM00t9U08TBL');

const storedUser = localStorage.getItem('user');
const userObject = storedUser ? JSON.parse(storedUser) : null;
const email = userObject ? userObject.name : null;

if (email) {
  // Retrieve the customer's ID
  const customer = await stripe.customers.search({
    query: 'email:"bermudez@berkeley.edu"'
  });

  // Check if a customer was found
  if (customer.data.length === 0) {
    console.log('Customer not found.'); // You can handle this error as needed
  } else {
    const customerId = customer.data[0].id;

    // Retrieve the customer's subscriptions
    const subscriptions = await stripe.subscriptions.list({
      customer: customerId
    });

    const subscriptionIds = [];

    // Iterate over the subscriptions and print their information
    subscriptions.data.forEach(subscription => {
      console.log(subscription.id);
      console.log(subscription.plan.name);
      console.log(subscription.status);

      subscriptionIds.push(subscription.status);
    });
  }
} else {
  console.log('Email not found.'); // You can handle this error as needed
}

export const Account = () => {
  const { user } = AuthData();

  return (
    <>
      <div className="title-page">
        <h1>
          <span>YOUR ACCOUNT</span>
        </h1>
      </div>
      <div className="app-block">
      <div className="center-container">
        <div className="center-content">
        <p>
          Modify your subscription: <a href='https://billing.stripe.com/p/login/test_14k6pE07J4aLaK4144' target="_blank"><b> Subscription Portal</b></a>
        </p>
        {email ? (
          <p>The following is your email: <b>{email}</b></p>
        ) : (
          <p>Email not found.</p>
        )}
        <p>You must use the same Email in this web application and in Alexa linking.</p>
        <p>Information regarding your playlists, shared playlists, audios, and storage will soon be shown here.</p>
      </div>
      </div>
      </div>
    </>
  );
};
