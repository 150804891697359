import { createContext, useContext, useState, useEffect } from "react";
import { RenderHeader } from "../components/structure/Header";
import { RenderMenu, RenderRoutes } from "../components/structure/RenderNavigation";
import { Auth } from 'aws-amplify';

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = () => {
  // Initialize the user state from localStorage, if available
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : { name: "", isAuthenticated: false };
  });

  const login = (userName) => {
    return new Promise((resolve, reject) => {
      if (userName) {
        const newUser = { name: userName, isAuthenticated: true };
        setUser(newUser);
        localStorage.setItem("user", JSON.stringify(newUser)); // Save to localStorage
        resolve("success");
      } else {
        reject("Did not sign in successfully");
      }
    });
  };

  const logout = () => {
    setUser({ name: "", isAuthenticated: false });
    localStorage.removeItem("user"); // Remove from localStorage
    Auth.signOut();
  };

  useEffect(() => {
    // This effect runs after the component has rendered.
    // It can be used to perform tasks when the component loads.
    Auth.currentAuthenticatedUser().then((user) => {
      if (user.attributes.email) {
        login(user.attributes.email);
      }
    });
  }, []); // The empty dependency array ensures this effect runs only once.

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <>
        <RenderHeader />
        <RenderMenu />
        <RenderRoutes />
      </>
    </AuthContext.Provider>
  );
};
