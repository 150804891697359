import { Link } from 'react-router-dom';

export const About = () => {


     return (
<div className="title-page"><h1><span>YOUR MUSIC, YOUR AUDIO, </span>YOUR ALEXA!</h1>
      <div className="app-container">
      <div className="app-block">
        <h2>Key Features</h2>
        <ul>
            <li>Play your playlists, your music, whatever you want!</li>
            <li>Simply ask Alexa to play your favorite songs or playlists.</li>
        </ul>
    </div>


    <div className="app-block">
        <h2>Share Playlists</h2>
        <ul>
            <li>You can share your created playlists with friends and family.</li>
            <li>All you have to do is share a unique ID with them, and that's it, they can immediately use them!</li>
        </ul>
    </div>

 
    <div className="app-block">
        <h2>Continue Where You Left Off</h2>
        <ul>
            <li>The Alexa app remembers where you left off, so just continue your listening.</li>
            <li>You can also rewind, fast forward, or start over.</li>
        </ul>
    </div>

    <div className="app-block">
        <h2>Multi-Device Sync</h2>
        <ul>
            <li>Seamlessly sync your music across all your Alexa-enabled devices.</li>
            <li>Switch between speakers without missing a beat.</li>
        </ul>
    </div>


    <div className="app-block">
        <h2>Audio File Types</h2>
        <ul>
            <li>You can upload aac, mp3, ogg, opus, and wav files.</li>
            <li>By the default, your audio compressor will minimize the file of your audio without sacrificing the quality.</li>
        </ul>
    </div>


    <div className="app-block">
        <h2>Hands-Free Convenience</h2>
        <ul>
            <li>No need to reach for your phone or remote – control music with your voice.</li>
            <li>Enjoy music without interruptions or distractions.</li>
        </ul>
    </div>
    </div>

    <div className="app-blockbottom">
    <h1><span>EXPERIENCE MY AUDIO APP TODAY!</span></h1>
        <h2>Experience the future of music with our voice-controlled app. Your favorite tunes are just a command away!</h2>
        <Link to={"/login"} className="cta-button">Get Started</Link>
    </div>
</div>
     )
}