import react, { Component } from 'react';
import { AddPlaylist } from './components-pages/addplaylist';
import { RetrievePlaylists } from './components-pages/retrieveplaylists';
import { RetrieveSharePlaylist } from './components-pages/retrievesharedplaylist';
import { AddSharedPlaylist } from './components-pages/addsharedplaylist';
import { Link } from 'react-router-dom';


export const Playlists = () => {

    return (
      <>
      <div className="title-page">
        <h1>
          <span>PLAYLIST CENTER</span>
        </h1>
      </div>
      <div className="app-block">
            
      <p>Check out more features with the monthly <Link to='/pricing'>subscriptions.</Link></p>
  
        <div className='playlistdetails-container'>
        <div className="playlistdetails-column">
        <div className="title-page"><h1><span>ADD PLAYLISTS</span>
        </h1>
        </div>
    < AddPlaylist />
    < AddSharedPlaylist />
    </div>
    <div className="playlistdetails-column">
    < RetrievePlaylists />
    </div>
    <div className="playlistdetails-column">
    < RetrieveSharePlaylist />
    </div>
    </div>
    </div>
    </>
    )
}