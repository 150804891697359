import react from 'react';

export function Footer() {
    return (
      <div className="main-footer">
        <div className="footer-container">
          <div className="footer-row">
            {/* Column1 */}
            <div className="footer-column">
              <h4>MY AUDIO APP</h4>
              <h1 className="list-unstyled">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="mailto:contact@myaudioapp.com">Contact us</a></li>
              </h1>
            </div>
            {/* Column2 */}
            <div className="footer-column">
              <h4>ALEXA SKILL</h4>
              <div className="list-unstyled">
              <li><a href="/alexa">How to install</a></li>
                <li><a href="/how">Alexa commands</a></li>
              </div>
            </div>
            {/* Column3 */}
            <div className="footer-column">
              <h4>PREMIUM</h4>
              <div className="list-unstyled">
              <li><a href="/pricing">Pricing</a></li>
              </div>
            </div>
          </div>
          
            <p className="footer-col-sm">
              &copy;{new Date().getFullYear()} My Audio App  All rights reserved |<a href='/terms'>Terms Of Service </a> | <a href='/privacy'> Privacy </a> 
            </p>
          
        </div>
      </div>
    );
  }