import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, NavLink } from 'react-router-dom';
import { AuthData } from '../../auth/AuthWrapper';
import { nav } from './navigation';

export const RenderRoutes = () => {
  const { user } = AuthData();

  return (
    <Routes>
      {nav.map((r, i) => {
        if (r.isPrivate && user.isAuthenticated) {
          return <Route key={i} path={r.path} element={r.element} />;
        } else if (!r.isPrivate) {
          return <Route key={i} path={r.path} element={r.element} />;
        } else return false;
      })}
    </Routes>
  );
};

export const RenderMenu = () => {
  const { user, logout } = AuthData();
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    if (window.innerWidth <= 768) {
      setMenuActive(!menuActive);
    }
  };

  const closeMenuOnResize = () => {
    if (window.innerWidth > 768 && menuActive) {
      setMenuActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', closeMenuOnResize);

    return () => {
      window.removeEventListener('resize', closeMenuOnResize);
    };
  }, [menuActive]);

  const MenuItem = ({ r, onClick }) => {
    const handleClick = () => {
      onClick();
    };

    return (
      <div className={`menuItem ${menuActive ? 'active' : ''}`} onClick={handleClick}>
        <NavLink to={r.path}>{r.name}</NavLink>
      </div>
    );
  };

  return (
    <div className="menu-container">
      <div className={`burger-icon ${menuActive ? 'active' : ''}`} onClick={toggleMenu}>
        ☰
      </div>
      <div className={`menu ${menuActive ? 'active' : ''}`}>
        {nav.map((r, i) => {
          if (!r.isPrivate && r.isMenu) {
            return <MenuItem key={i} r={r} onClick={toggleMenu} />;
          } else if (user.isAuthenticated && r.isMenu) {
            return <MenuItem key={i} r={r} onClick={toggleMenu} />;
          } else return false;
        })}

        {user.isAuthenticated ? (
          <div className={`menuItem ${menuActive ? 'active' : ''}`}>
            <Link to={'#'} onClick={logout}>
              LOGOUT
            </Link>
          </div>
        ) : (
          <div className={`menuItem ${menuActive ? 'active' : ''}`}>
            <Link to={'login'}>LOGIN</Link>
          </div>
        )}
      </div>
    </div>
  );
};
