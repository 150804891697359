import { configureStore, createSlice } from '@reduxjs/toolkit';

const initialState = {
  dynamoDBUpdated: false,
};

const dynamoDBSlice = createSlice({
  name: 'dynamoDB',
  initialState,
  reducers: {
    setDynamoDBUpdated: (state, action) => {
      state.dynamoDBUpdated = action.payload;
    },
  },
});

const store = configureStore({
  reducer: dynamoDBSlice.reducer,
});

export const { setDynamoDBUpdated } = dynamoDBSlice.actions;

export default store;