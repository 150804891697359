import React, { useState, createContext, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { DynamoDB } from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { setDynamoDBUpdated } from '../../../Store';


// Initialize DynamoDB
const dynamodb = new DynamoDB({
  region: 'us-east-1',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const validatePlaylistName = (value) => {
  // Check for the word 'playlist'
  if (value.toLowerCase().includes('playlist')) {
    return 'PLAYLIST word is not allowed to avoid confusion when using Alexa. Remove the word to add playlist.';
  }

  // Check for special characters using a regular expression
  const specialCharacterRegex = /^[a-zA-Z0-9\s]*$/; // Allows only alphanumeric characters and spaces
  if (!specialCharacterRegex.test(value)) {
    return 'Special characters are not allowed in the playlist name.';
  }

  // Return undefined if the input is valid
  return undefined;
};

// Function to add an item to DynamoDB
const addItemToDynamoDB = (item) => {
  return new Promise((resolve, reject) => {
    const params = {
      TableName: 'MAP-playlist', // Replace with your DynamoDB table name
      Item: {
        playlistID: { S: item.playlistID },
        playlistName: { S: item.playlistName },
        userEmail: { S: item.userEmail },
        shared: { S: item.shared },
        playlistDetails: { S: item.playlistDetails },
        audioInfo: { L: [] },
        offset: { S: 'restart' },
      },
    };

    dynamodb.putItem(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });

    const storedUser = localStorage.getItem('user');
    const userObject = JSON.parse(storedUser);
    const email = userObject.name;

    const sharedParams = {
      TableName: 'MAP-sharedList', // Replace with your DynamoDB table name
      Item: {
        id: { S: uuidv4() },
        userEmail: { S: item.userEmail },
        playlistID: { S: item.playlistID },
        playlistName: { S: item.playlistName },
        isShared: { S: item.shared },
        sharedUsers: {S: email },
        playlistDetails: { S: item.playlistDetails },
        searchplaylistName: { S: item.playlistName.toLowerCase() },
        offset: { S: 'restart' },
        approved: { S: 'yes' },
      },
    };

    dynamodb.putItem(sharedParams, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });

  });
};

export const AddPlaylist = () => {
  const [showForm, setShowForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [playlistID, setPlaylistID] = useState('');
  const dispatch = useDispatch();

  function generateShortIdentifier(length) {
    const characters = uuidv4();
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  function removeDashesFromUUID(uuid) {
    // Remove dashes from the UUID string
    return uuid.replace(/-/g, '');
  }

  const shortenedUUID = removeDashesFromUUID(generateShortIdentifier(15));

  const storedUser = localStorage.getItem('user');
  const userObject = JSON.parse(storedUser);
  const email = userObject.name;


  const toggleForm = () => {
    setShowForm(!showForm);
    if (!showForm) {
      // If the form is being opened, clear the success message
      setSuccessMessage('');
    }
  };

  const handleSubmit = async (values) => {
    try {
      const data = await addItemToDynamoDB(values);
      setPlaylistID(values.playlistID);
      setSuccessMessage(`Your playlist has been added successfully with ID: ${values.playlistID}. If you selected to share this playlist, this is the ID you need to share.`);
      toggleForm(); // Close the form
      dispatch(setDynamoDBUpdated(true));
    } catch (error) {
      console.error('Error adding item to DynamoDB:', error);
    }
  };



  return (
    <>
    <div>
      <div className="app-block">
      <center>
      <div className='successMessage'>{successMessage && <p>{successMessage}</p>}</div>
      <button className='addbutton' onClick={toggleForm}>{showForm ? 'Oh Nevermind' : 'Add Playlist'}</button></center>
      {showForm && (
        <Formik
          initialValues={{
            playlistID: shortenedUUID,
            playlistName: '',
            userEmail: email,
            shared: '',
            playlistDetails: '',
          }}
          onSubmit={handleSubmit}
        >
          <Form className="playlist-form">
            <div>
              <label htmlFor="playlistID">Playlist ID: (automated)</label>
              <Field type="text" id="playlistID" name="playlistID" disabled />
              <p>This is a unique ID or Playlist ID to your playlist. If you decide to share the playlist, this is what you need to provide.</p>
            </div>
            <div>
            <label htmlFor="playlistName">Playlist Name: (for Alexa use)</label>
          <Field
            type="text"
            id="playlistName"
            name="playlistName"
            required
            validate={validatePlaylistName}
          />
                    <ErrorMessage name="playlistName">
            {(message) => (
              <div className="error-message">{message}</div>
            )}
          </ErrorMessage>
              <p>HOW TO USE: Alexa, play "PLAYLIST NAME" Playlist. Do not include playlist in the naming. You cannot have duplicate playlist names.</p>
            </div>
            <div>
              <label htmlFor="playlistDetails">Playlist details</label>
              <Field type="text" id="playlistDetails" name="playlistDetails" required/>
              <p>Details about the playlist. UNCHANGEABLE after creation.</p>
            </div>
            <div className="hidden-field">
              <label htmlFor="userEmail" >User Email:</label>
              <Field type="text" id="userEmail" name="userEmail" disabled />
            </div>
            <div>
            <label htmlFor="shared">Are you sharing it?</label>
               <Field as="select" id="shared" name="shared" required>
               <option value=""></option>
               <option value="Yes">YES</option>
               <option value="No">NO</option>
               </Field>
               <p>By selection "YES", anyone with the link can add this playlist for use. However, it will first need your approval. UNCHANGEABLE after creation.</p>
            </div>
            <button type="submit" >Add Playlist</button>
          </Form>
        </Formik>
      )}
    </div>
    </div>
    </>
  );   
};
