import react, { Component } from 'react';
import { AddPlaylist } from './components-pages/addplaylist';


export const How = () => {

    return (       
      <>
      <div className="title-page"><h1><span>HOW DOES IT WORKS</span>
                 </h1>
                 </div>
                 <div className="app-block">
                 
                 <header className="headerhow">PLAY YOUR SONGS (YOUR PLAYLISTS)</header>
                 <div className="howsection">
<subheader className="subheaderhow">Use these commands to play specific songs you have uploaded to lists. This works regardless of the playlists it belongs to. However, it does not work on lists shared with you.</subheader>
<div className="bubble-box-container">
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, Tell My Audio to Play "Meditation"</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, Tell My Audio to Play "Bohemian Rhapsody"</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, tell MyAudioApp to play "Salem's Lot by Stephen King"</p>
</div>
</div> 
</div> 
                 <header className="headerhow">PLAY YOUR SONGS (SHARED PLAYLISTS)</header>
                 <div className="howsection">
<subheader className="subheaderhow">Use these commands to play specific songs from playlists you have been shared.</subheader>
<div className="bubble-box-container">
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, Tell My Audio to Play "Meditation" from "calm" Playlist</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, Tell My Audio to Play "Bohemian Rhapsody" from "80s" Playlist</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, tell MyAudioApp to play "Salem's Lot by Stephen King" from "Horror" Playlist</p>
</div>
</div>
                 </div>
                 <header className="headerhow">STOP, PLAY, RESUME</header>
                 <div className="howsection">
<subheader className="subheaderhow">Use these commands to play to stop, pause, or continue an audio.</subheader>
<div className="bubble-box-container">
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, Pause</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, Resume</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, tell MyAudioApp to "continue"</p>
</div>
</div>
                 </div>
                 <header className="headerhow">NEXT, RESTART, FAST FORWARD, REWIND</header>
                 <div className="howsection">
<subheader className="subheaderhow">Use these commands to skip, restart, fast forward, or rewind any audio.</subheader>
<div className="bubble-box-container">
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, restart</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, next</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, skip forward "2 minutes and 30 seconds"</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, rewind "50 seconds"</p>
</div>
</div>
                 </div>
                 <header className="headerhow">PLAY PLAYLIST (OWN OR SHARED)</header>
                 <div className="howsection">
                 <subheader className="subheaderhow">Use these commands to play specific playlists. It is critical to use the word "Playlist"  at the end. Otherwise, it will not work.</subheader>
<div className="bubble-box-container">
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, tell My Audio to Play "Sports" Playlist</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, tell MyAudioApp to play "Christmas" Playlist</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, tell MyAudioApp to play "80s Hits" Playlist</p>
</div>
</div>
                 </div>
                 <header className="headerhow">PLAY AUDIOS BASED ON TAGS</header>
                 <div className="howsection">
<subheader className="subheaderhow">Use these commands to play types of audios based on tags you've entered. This will automatically create a curated playlist based on those tags.</subheader>
<div className="bubble-box-container">
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, tell My Audio Play "horror"</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, tell My Audio to Play "disney"</p>
</div>
<div className="alexa-command-bubble-box">
  <img className="alexa-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Amazon_Alexa_blue_logo.svg/1200px-Amazon_Alexa_blue_logo.svg.png" />
  <p className="alexa-command-text">Alexa, tell My Audio to Play "wimpy kid"</p>
</div>
                 </div>
                 </div>
                 </div>
                     
                 </>
              
    )
}