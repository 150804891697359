import React, { useState, createContext, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { DynamoDB } from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';


// Initialize DynamoDB
const dynamodb = new DynamoDB({
  region: 'us-east-1',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});



export const AddSharedPlaylist = () => {
  const [showForm, setShowForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [playlistUniqueID, setPlaylistUniqueID] = useState('');
  const [items, setItems] = useState([]);
  const [shared, setShared] = useState('');


  const storedUser = localStorage.getItem('user');
  const userObject = JSON.parse(storedUser);
  const email = userObject.name;

  // Function to add an item to DynamoDB
  const DynamoDBCheck = async (playlistUniqueID) => {
    try {
      const params = {
        TableName: 'MAP-playlist', // Replace with your DynamoDB table name
        KeyConditionExpression: 'playlistID = :playlistID',
        ExpressionAttributeValues: {
          ':playlistID': { S: playlistUniqueID.toString() },
        },
      };
      
      const results = await dynamodb.query(params).promise();
  
      const items = results.Items.map((item) => {
        return {
          sharedInfo: item.shared.S,
          userEmail: item.userEmail.S,
          playlistName: item.playlistName.S,
          playlistDetails: item.playlistDetails.S,
          offset: item.offset.S,
          // Add more attributes as needed
        };
      });
      setItems(items);
  
      
  
      const paramsCheck = {
        TableName: 'MAP-sharedList', // Replace with your DynamoDB table name
        IndexName: 'playlistID-index', // Replace with the name of your composite GSI
        KeyConditionExpression: 'playlistID = :playlist',
        ExpressionAttributeValues: {
        ':playlist': { S: playlistUniqueID.toString() },
      },
    };
      
      const resultsCheck = await dynamodb.query(paramsCheck).promise();
  
      const items1 = resultsCheck.Items.map((item1) => {
        return {
          sharedUsers: item1.sharedUsers.S,
          // Add more attributes as needed
        };
      });
      setItems(items1);

      // Check if "shared" is equal to "Yes"

      const sharedItem = items.find((item) => item.sharedInfo === 'Yes');

      if (sharedItem) {
        const sharedItem1 = items1.find((item1) => item1.sharedUsers === email);
        if (sharedItem1) {
          setSuccessMessage('You already have this shared playlist.');
        } else {
        // If "shared" is "Yes", call addtoDynamodb
        await addtoDynamodb(sharedItem.userEmail, playlistUniqueID, sharedItem.sharedInfo, email, sharedItem.playlistName, sharedItem.playlistDetails, sharedItem.offset);
      }
    } else {
      setSuccessMessage('This list does not exist or has not been shared.');
    }

  } catch (error) {
    console.error('Error fetching items:', error);
    setSuccessMessage('An error occurred while checking the playlist.');
  }
}
  
  const addtoDynamodb = async (userEmail, playlistUniqueID, sharedInfo, email, playlistName, playlistDetails, offset) => {
    return new Promise((resolve, reject) => {
      const sharedParams = {
        TableName: 'MAP-sharedList', // Replace with your DynamoDB table name
        Item: {
          id: { S: uuidv4() },
          userEmail: { S: userEmail },
          playlistID: { S: playlistUniqueID },
          isShared: { S: sharedInfo },
          sharedUsers: { S: email },
          playlistName: {S: playlistName},
          playlistDetails: {S: playlistDetails},
          searchplaylistName: {S: playlistName.toLowerCase()},
          offset: {S: offset.toLowerCase()},
          approved: {S: 'no'},
        },
      };
  
      dynamodb.putItem(sharedParams, (err, data) => {
        setSuccessMessage(`The shared list: ${playlistUniqueID}, has been sent for approval to the owner. Once approved, it will show here and you can use it.`);
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };


  const toggleForm = () => {
    setShowForm(!showForm);
    if (!showForm) {
      // If the form is being opened, clear the success message
      setSuccessMessage('');
    }
  };

  const handleSubmit = async (values) => {
    try {
      // If the playlistUniqueID value is not defined or empty, return early
      if (!values.playlistUniqueID) {
        console.error('playlistUniqueID is not defined or is empty');
        return;
      }
  
      // Toggle the form
      toggleForm();
  
      // Pass the playlistUniqueID value to DynamoDBCheck
      const data = await DynamoDBCheck(values.playlistUniqueID);
  
      // Add the item to DynamoDB
      // const data1 = await addtoDynamodb(values, shared, userEmail);
    } catch (error) {
      console.error('Error adding item to DynamoDB:', error);
    }
  };

  return (
    <div>
      <div className="app-block">
      <center>
      <div className='successMessage'>{successMessage && <p>{successMessage}</p>}</div>
      <button className='addbutton' onClick={toggleForm}>{showForm ? 'Oh Nevermind' : 'Add Shared Playlist'}</button></center>
      {showForm && (
        <Formik
          initialValues={{
            playlistUniqueID: '',
          }}
          onSubmit={handleSubmit}
        >
          <Form className="playlist-form">
            <div>
              <label htmlFor="playlistUniqueID">Enter Public Unique ID</label>
              <Field type="text" id="playlistUniqueID" name="playlistUniqueID" required/>
              <p>All public playlists can be deleted and modified at any point by the original owner. 
                Also, listening to public audio DOES COUNT towards your audio quota but not 
                towards your storage.
              </p>
            </div>

            <button type="submit" className='button-container'>Add Shared Playlist</button>
          </Form>
        </Formik>
      )}
    </div>
    </div>
  );   
};
