export const RenderHeader = () => {

     return (
          <div className="header">
               <div className="logo">
                    <img onClick={ () => { window.location.href="https://www.myaudioapp.com"} } src="/MyAudioAppLogo.png" alt="MyAudioApp"/>
               </div>
               <h1><i>MY AUDIO APP</i></h1>
          </div>
     )
}