import React from 'react';

export const Terms = () => {
    return (
        <>
            <div className="title-page">
                <h1><span>TERMS AND CONDITIONS</span></h1>
                </div>
                <div className="app-block">
                <div className='center-container'>
                    <div className='center-content'>
    <h2>1. Acceptance of Terms</h2>
    <p>By accessing or using our website and services, you agree to be bound by these Terms and any applicable laws and regulations. If you do not agree with these Terms, please do not use our website and services.</p>

    <h2>2. User Accounts</h2>
    <p>2.1. To use our services, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account.</p>
    <p>2.2. You agree to provide accurate, current, and complete information during the registration process. You also agree not to impersonate someone else or provide false information.</p>
    <p>2.3. You must notify us immediately of any unauthorized use of your account or any other breach of security.</p>

    <h2>3. User-Generated Content</h2>
    <p>3.1. Our website allows users to upload audio files. You are solely responsible for the content you upload. You must ensure that you have all necessary rights and permissions to upload and stream the audio files. We are not responsible for the content provided by users.</p>
    <p>3.2. You may not upload or share any content that is illegal, infringes on the intellectual property rights of others, is harmful, defamatory, or otherwise violates applicable laws and regulations.</p>

    <h2>4. Use of Services</h2>
    <p>4.1. You agree to use our services only for lawful purposes. Any misuse, abuse, or manipulation of our services will result in the immediate suspension or termination of your account.</p>
    <p>4.2. You may not engage in activities that could harm, overload, or disrupt our website or services, including but not limited to, using automated scripts or bots.</p>

    <h2>5. Intellectual Property</h2>
    <p>5.1. All content on our website, including but not limited to text, graphics, logos, but not audio files, is protected by intellectual property rights and is owned by us or our licensors. 
        You may not use or reproduce this content without our express written consent.</p>

    <p>5.2. By uploading audio files to our server, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute the content for the purpose of providing our services.</p>

    <h2>6. Privacy</h2>
    <p>6.1. Your use of our website and services is subject to our Privacy Policy, which can be found [provide a link to your Privacy Policy]. 
        By using our services, you consent to the collection, use, and sharing of your information as described in our Privacy Policy.</p>

    <h2>7. Termination</h2>
    <p>We reserve the right to suspend, terminate, or restrict your access to our website and services at our discretion, without prior notice, if you violate these Terms.</p>

    <h2>8. Changes to Terms</h2>
    <p>We may update these Terms from time to time. Any changes will be effective upon posting on our website. It is your responsibility to review these Terms periodically for updates</p>

    <p>5.2. By uploading audio files to our server, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute the content for the purpose of providing our services.</p>

    <h2>9. Disclaimer of Warranties</h2>
    <p>Your use of our website and services is at your own risk. We provide our services on an "as is" and "as available" basis, without any warranties of any kind, either expressed or implied</p>

    <h2>10. Limitation of Liability</h2>
    <p>To the extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of our website and services.</p>

    <h2>11. Governing Law</h2>
    <p>These Terms are governed by and construed in accordance with the laws of the United States of America.</p>

    <h2>12. Contact Information</h2>
    <p>If you have any questions or concerns regarding these Terms, please contact us at contact@myaudioapp.com.</p>

    <p>By using our website and services, you acknowledge that you have read, understood, and agreed to these Terms and our Privacy Policy. These Terms constitute the entire agreement between you and [Your Company Name] regarding the use of our website and services.</p>
            </div>
        </div>
                </div>
                </>
    );
};