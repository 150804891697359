import { useEffect, useInsertionEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from "aws-amplify";
import { Hub } from "aws-amplify";
import awsExports from "../../aws-exports";
Amplify.configure(awsExports);

Hub.listen('auth', async (data) => {
  switch (data.payload.event) {
    case 'signIn':
      const { login } = AuthData();
      console.log('it listens')
      break;
    case 'signOut':
      // Handle user sign-out
      // You can add sign-out handling logic here if needed
      break;
    default:
      break;
  }
});


export const Login = () => {
  const navigate = useNavigate();
  const { login } = AuthData();
  //const [userData, setUserData] = useState({ userName: "" });

  Auth.currentAuthenticatedUser().then((user) => {
    login(user.attributes.email)})

  
  useEffect(() => {
    const checkAuthenticatedUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        navigate('/account');
          Auth.currentAuthenticatedUser().then((user) => {
            login(user.attributes.email);

            console.log('user email = ' + user.attributes.email);
          });

      //    Hub.listen('auth', async (data) => {
        //    switch (data.payload.event) {
          //    case 'signIn':
           //     console.log('it does work');
            //    Auth.currentAuthenticatedUser().then((user) => {
            //      login(user.attributes.name);
            //      console.log('user email = ' + user.attributes.name);
             //   });
             //   break;
            //  case 'signOut':
           //     // Handle user sign-out
                // You can add sign-out handling logic here if needed
            //    break;
          //    default:
          //      break;
         //   }
         // });

      } catch (err) {
        // Handle errors or the case where the user is not authenticated
      }
    };
    

    checkAuthenticatedUser();

  }, []);


  return (
    <>
               
      <div className="title-page"><h1><span>LOGIN WITH AMAZON</span></h1>
                 </div>
                 <div class="app-block">
                 <p>You must sign in with the email address linked to your Alexa account.</p>  
    <Authenticator>
      {({ signOut, user }) => (
        <main>
          <h1>Hello {user.attributes.name}</h1>
          <button onClick={signOut}>SIGN OUT</button>
        </main>
      )}
    </Authenticator>
    </div>
    </>
  );
};