import React, { useState, useEffect, useContext, createContext, useRef } from 'react';
import { DynamoDB, S3 } from 'aws-sdk'; // Use the correct import for your AWS SDK version
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faPlay} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDynamoDBUpdated } from '../../../Store';


// Initialize DynamoDB
const dynamodb = new DynamoDB.DocumentClient({
  region: 'us-east-1',
  // Replace these with your own AWS credentials
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new S3({
  region: 'us-east-1',
  // Replace these with your own AWS credentials
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

// Confirmation component
function DeleteConfirmation({ onCancel, onConfirm }) {
  return (
    <div className="button-confirmcancel">
      <p>Are you sure you want to delete this audio? this will PERMANENTLY DELETE this audio in this playlist.</p>
      <button onClick={onConfirm}>Confirm</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
}


export function RetrieveAudios() {
  const [items, setItems] = useState([]);
  const [confirmation, setConfirmation] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);
  const navigate = useNavigate();
  const audioRefs = useRef([]);
  const [currentTime, setCurrentTime] = useState(0);

  const storedUser = localStorage.getItem('user');
  const userObject = JSON.parse(storedUser);
  const email = userObject.name;

  const [clickedLink, setClickedLink] = useState(false);

  const { id } = useParams();
  const playlistID = id;

  const isDynamoDBUpdated = useSelector(state => state.dynamoDBUpdated);
  const dispatch = useDispatch();

  
  const handleLinkClick = (playlistID) => {
    setClickedLink(true);
    navigate(`/playlist/${playlistID}`);
  };

  const fetchItemsByUserEmail = async () => {
    try {
    const params = {
      TableName: 'MAP-audios', // Replace with your DynamoDB table name
      IndexName: 'userEmail-playlistID-index', // Replace with the name of your composite GSI
      KeyConditionExpression: 'userEmail = :email AND playlistID = :playlist',
      ExpressionAttributeValues: {
        ':email': email,
        ':playlist': playlistID,
      },
    };

      const results = await dynamodb.query(params).promise();

      const items = results.Items.map((item) => {
        return {
          audioID: item.audioID,
          audioTitle: item.audioTitle,
          audioTags: item.audioTags,
          s3Link: item.s3Link,
          // Add more attributes as needed
        };
      });

      setItems(items);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data or perform any actions you want
        await fetchItemsByUserEmail();
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    if (isDynamoDBUpdated) {
      // Run fetchData when isDynamoDBUpdated becomes true
      fetchData();

      // Reset the state in the Redux store
      dispatch(setDynamoDBUpdated(false));
    }
  }, [isDynamoDBUpdated, dispatch]);

  useEffect(() => {
    fetchItemsByUserEmail();
  }, []);

  const handleDeleteClick = (audioID, s3Link) => {
    setDeletingItem({ audioID, s3Link });
    setConfirmation(true);
  };

  const confirmDelete = async () => {
    if (deletingItem) {
      await deleteAudio(deletingItem.audioID, deletingItem.s3Link);
      setDeletingItem(null);
    }
    setConfirmation(false);
  };

  const cancelDelete = () => {
    setDeletingItem(null);
    setConfirmation(false);
  };
  

  const [activeAudioIndex, setActiveAudioIndex] = useState(null);

  const handleAudioEnded = (index) => {
    if (audioRefs.current[index]) {
      audioRefs.current[index].currentTime = 0; // Reset playback to the beginning
      setCurrentTime(0);
      setActiveAudioIndex(null); // Hide the player
    }
  };

  const handleTimeUpdate = (index) => {
    if (audioRefs.current[index] && currentTime > 20) {
      audioRefs.current[index].pause(); // Pause the audio after 20 seconds
      setCurrentTime(0);
      setActiveAudioIndex(null); // Hide the player
    }
  };

  const toggleAudioPlayer = (index) => {
    if (activeAudioIndex === index) {
      setActiveAudioIndex(null); // Hide the player if it's already open
    } else {
      setActiveAudioIndex(index); // Show the player for the clicked audio
      if (audioRefs.current[index]) {
        audioRefs.current[index].currentTime = 0; // Reset playback to the beginning
        setCurrentTime(0);
        audioRefs.current[index].play(); // Start playback
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Check the current time of the active audio every second
      if (activeAudioIndex !== null && audioRefs.current[activeAudioIndex]) {
        setCurrentTime(audioRefs.current[activeAudioIndex].currentTime);
        if (currentTime > 20) {
          audioRefs.current[activeAudioIndex].pause();
          setCurrentTime(0);
          setActiveAudioIndex(null);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [activeAudioIndex, currentTime]);

  const deleteAudio = async (audioID, s3Link) => {
    setConfirmation(
      `Are you sure you want to delete this audio? This action will delete the record permanently!`
    );
    try {
      // Delete the record from DynamoDB
      await dynamodb
        .delete({
          TableName: 'MAP-audios', // Replace with your DynamoDB table name
          Key: {
            audioID: audioID,
            userEmail: email,
          }
        })
        .promise();

      // Extract the S3 object key from the S3 link
      const s3Key = s3Link.split('/').pop();

      // Delete the corresponding object from S3
      await s3
        .deleteObject({
          Bucket: 'myaudioappbucket',
          Key: s3Key,
        })
        .promise();

        const playlistParams = {
          TableName: 'MAP-playlist', // Replace with your DynamoDB table name
          Key: {
            playlistID: playlistID,
            userEmail: email,
          }
        };
    
        const playlistData = await dynamodb.get(playlistParams).promise();
    
        if (playlistData.Item && playlistData.Item.audioInfo) {
          // Filter the audioInfo array to keep items where Link doesn't match
          playlistData.Item.audioInfo = playlistData.Item.audioInfo.filter((audioItem) => {
            return audioItem.link !== s3Link;
          });
    
          // Update the "audioInfo" field in the playlist
          await dynamodb
            .update({
              TableName: 'MAP-playlist',
              Key: {
                playlistID: playlistID,
                userEmail: email,
              },
              UpdateExpression: 'SET audioInfo = :audioInfo',
              ExpressionAttributeValues: {
                ':audioInfo': playlistData.Item.audioInfo,
              },
            })
            .promise();
        }
    
        // Refresh the list of items after deletion
        fetchItemsByUserEmail();
      } catch (error) {
        console.error('Error deleting audio:', error);
      }
    };

  

  return (
    <>    
    <div className="app-block">
      <div className="title-page"><h1><span>AUDIOS IN THIS PLAYLIST</span>
        </h1>
        <p>You can play the first 20 seconds of any audio below by clicking on the title. In Alexa, you can play 
          an audio by saying "Alexa, ask My Audio to play "[Audio Name]" or "[TAG]". Check out how to use the <Link to='/how'>Alexa Commands</Link>. 
          The easier the name, the better it will work on Alexa.
        </p>
        </div>
        {items.map((item, index) => (
          <div key={index} className="audio-set">
            <a
              className="audio-title"
              onClick={() => toggleAudioPlayer(index)}
              style={{
                color: activeAudioIndex === index ? 'yellow' : 'white',
              }}
            >
              {item.audioTitle}  <FontAwesomeIcon icon={faPlay} style={{ marginRight: '5px' }} />
            </a>
            <p className="audio-tags">
              {item.audioTags.split(',').map((tag, index) => (
                <span key={index} className="audio-tag">
                  [ {tag.trim()} ]
                </span>
              ))}
            </p>
          <button className="delete-button" onClick={() => handleDeleteClick(item.audioID, item.s3Link)}>
                Delete
              </button> {confirmation && deletingItem && deletingItem.audioID === item.audioID && deletingItem.s3Link === item.s3Link && (
  <DeleteConfirmation onCancel={cancelDelete} onConfirm={confirmDelete} />
)}
          <audio
            ref={(ref) => (audioRefs.current[index] = ref)}
            controls
            className={activeAudioIndex === index ? 'audioactive' : 'audionone'}
            controlsList="nodownload"
            onEnded={() => handleAudioEnded(index)}
            onTimeUpdate={() => handleTimeUpdate(index)}
          >
            <source src={item.s3Link} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
          </div>
        ))}
    </div>
    </>
  );
}