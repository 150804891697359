import AWS from "aws-sdk";
import { useState, useEffect, createContext } from "react";
import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDynamoDBUpdated } from '../../../Store';




export function AddAudio() {
  const [files, setFiles] = useState([]);
  const [audioTitles, setAudioTitles] = useState([]);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [file, setFile] = useState(null);  // Add this line
  const [audioID, setAudioID] = useState('');
  const [audioTitle, setAudioTitle] = useState('');
  const [audioTags, setAudioTags] = useState([]);
  const [progress, setProgress] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [isFileInputVisible, setIsFileInputVisible] = useState(false);
  const { id } = useParams();
  const playlistID = id;
  const [enteredTags, setEnteredTags] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch();
  const [tagsForAllFiles, setTagsForAllFiles] = useState('');
  const [filesPresent, setFilesPresent] = useState(false);


  const handleTagsForAllFilesChange = (e) => {
    setTagsForAllFiles(e.target.value);
  };
  
  
 

  const initialValues = {
    audioTitle: '',
    audioTags: '',
  };

  const validate = (values) => {
    const errors = {};
    // Add validation rules if required
    return errors;
  };

  
  const S3_BUCKET = "myaudioappbucket";
  const REGION = "us-east-1";
  const DYNAMODB_TABLE = "MAP-audios";
  const DDB_Playlist = "MAP-playlist"

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const dynamodb = new AWS.DynamoDB.DocumentClient({ region: REGION });

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const allowedExtensions = ['.aac', '.mp3', '.ogg', '.opus', '.wav'];
  
    // Filter files to include only allowed extensions
    const filteredFiles = Array.from(selectedFiles).filter((file) => {
      const fileExtension = file.name.toLowerCase().slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
      return allowedExtensions.includes('.' + fileExtension);
    });
  
    // Check for files that do not match allowed extensions
    const invalidFiles = Array.from(selectedFiles).filter((file) => !filteredFiles.includes(file));
    setFilesPresent(true);
    if (invalidFiles.length > 0) {
      // Set an error message or handle the error as neededsetsuasdfasdf
      setSuccessMessage('Error: Invalid file type. Please upload files with extensions: ' + allowedExtensions.join(', '));
      // You can also set an error state or show an alert to the user
      // setErrorState(true);
      return;
    }
  
    setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
  
    const selectedTitles = filteredFiles.map((file) => {
      const fileName = file.name;
      return fileName.substring(0, fileName.lastIndexOf('.'));
    });
  
    setAudioTitles((prevTitles) => [...prevTitles, ...selectedTitles]);
  };
  

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
  
    const droppedFilesArray = Array.from(e.dataTransfer.files);
    setDroppedFiles((prevFiles) => [...prevFiles, ...droppedFilesArray]);
  
    // Perform the same actions as when a file is picked using the file picker
    handleFiles(droppedFilesArray);
  };
  
  const handleFiles = (selectedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  
    const selectedTitles = Array.from(selectedFiles).map((file) => {
      const fileName = file.name;
      return fileName.substring(0, fileName.lastIndexOf('.'));
    });
  
    setAudioTitles((prevTitles) => [...prevTitles, ...selectedTitles]);
  };

  const handleAudioTitleChange = (e, index) => {
    const updatedTitles = [...audioTitles];
    updatedTitles[index] = e.target.value;
    setAudioTitles(updatedTitles);
  };

  const handleAudioTagChange = (e, index) => {
    const updatedTags = [...audioTags];
    updatedTags[index] = e.target.value;
    setAudioTags(updatedTags);
  };



  const toggleFileInput = () => {
    setIsFileInputVisible(!isFileInputVisible);
    setSuccessMessage('');
    
  };

  const uploadFile = async () => {
    if (!files || files.length === 0) {
      alert("Please select one or more files to upload.");
      return;
    }

    setTotalFiles(files.length); // Set total number of file

    for (let i = 0; i < files.length; i++) {
      const selectedFile = files[i];
      setCurrentFileIndex(i);
      const fileExtension = selectedFile.name.split('.').pop();
      const key = `${uuidv4()}.${fileExtension}`;
  
      const params = {
        Bucket: S3_BUCKET,
        Key: key,
        Body: selectedFile,
      };
  
      const upload = s3.upload(params);
  
  
      upload.on('httpUploadProgress', (evt) => {
        const uploadedBytes = evt.loaded;
        const totalBytes = evt.total;
        const uploadProgress = Math.round((uploadedBytes / totalBytes) * 100);
        setProgress(uploadProgress);
        setFormSubmitted(true);
      });
  
      try {
        const data = await upload.promise();
        saveAudioMetadataToDynamoDB(key, audioTitles[i], audioTags[i]);
      } catch (err) {
        console.error("Error uploading file: ", err);
      }
    }
    setIsFormVisible(false);
    setTimeout(() => {
      dispatch(setDynamoDBUpdated(true));
    }, 1000);
  };

  useEffect(() => {
    // This useEffect ensures that totalFiles is correctly set
    // after the asynchronous update in uploadFile
    if (files.length > 0) {
      setTotalFiles(files.length);
    }
  }, [files]);

  const storedUser = localStorage.getItem('user');
  const userObject = JSON.parse(storedUser);
  const email = userObject.name;

  const numberToWords = require('number-to-words');

  const generateAIVersions = (input) => {
    const words = input.split(' ');
  
    const permutations = [];
    for (let i = 0; i < words.length; i++) {
      for (let j = i + 1; j <= words.length; j++) {
        const subset = words.slice(i, j);
        const subsetString = subset.join(' ').toLowerCase();
        if (subsetString.trim() !== '') {
          permutations.push(subsetString);
  
          // Include variations with no special characters
          const withoutSpecialChars = subsetString.replace(/[^\w\s]/gi, '');
          permutations.push(withoutSpecialChars);
  
          // Include variations with numbers converted to words
          const withNumbersAsWords = subset.map((word) =>
            isNaN(word) ? word : numberToWords.toWords(word)
          );
          permutations.push(withNumbersAsWords.join(' ').toLowerCase());
  
          // Include variations with '-' replaced by 'by'
          const withByInsteadOfDash = subsetString.replace(/-/g, 'by');
          permutations.push(withByInsteadOfDash);
        }
      }
    }
  
    const variations = [];
    words.forEach((word, index) => {
      // Include variations with special characters removed
      const withoutSpecialChars = word.replace(/[^\w\s]/gi, '').toLowerCase();
      variations.push(withoutSpecialChars);
  
      // Include variations with words added
      const addedWord = `${word} added`.toLowerCase();
      variations.push(addedWord);
  
      // Include variations with words removed (1 word removed)
      const withoutOneWord = [...words];
      withoutOneWord.splice(index, 1);
      const withoutOneWordString = withoutOneWord.join(' ').toLowerCase();
      if (withoutOneWordString.trim() !== '') {
        variations.push(withoutOneWordString);
      }
  
      // Include variations with words removed (2 words removed)
      const withoutTwoWords = [...words];
      withoutTwoWords.splice(index, 1);
      withoutTwoWords.splice(index + 1, 1);
      const withoutTwoWordsString = withoutTwoWords.join(' ').toLowerCase();
      if (withoutTwoWordsString.trim() !== '') {
        variations.push(withoutTwoWordsString);
      }
  
      // Include variations with number-to-words conversion
      if (!isNaN(word)) {
        const wordAsNumber = numberToWords.toWords(word);
        variations.push(wordAsNumber.toLowerCase());
      }
    });
  
    const aiVersions = [...permutations, ...variations].join(', ');
  
    // Replace consecutive commas with a single comma
    const cleanedAiVersions = aiVersions.replace(/,+/g, ',');
  
    return cleanedAiVersions;
  };
  


  const saveAudioMetadataToDynamoDB = (key, audioTitle, audioTags, index) => {
    const aiVersions = generateAIVersions(audioTitle);
    const s3Link = `https://${S3_BUCKET}.s3.amazonaws.com/${key}`

    const aiVersionsTitle = generateAIVersions(audioTitle);
    const aiVersionsTags = generateAIVersions(audioTags);

    const params = {
      TableName: DYNAMODB_TABLE,
      Item: {
        audioID: key,
        s3Link: s3Link,
        playlistID, playlistID,
        audioTitle: audioTitle,
        userEmail: email,
        audioTags: audioTags,
        searchaudioTitle: aiVersionsTitle,
        searchaudioTags: aiVersionsTags,
      },
    };

    dynamodb.put(params, (err, data) => {
      if (err) {
        console.error("Error saving metadata to DynamoDB: ", err);
      }
    });


    const secondaryParams = {
      TableName: 'MAP-playlist',
      Key: {
        playlistID: playlistID,
        userEmail: email,
      },
      UpdateExpression: 'SET #audioInfo = list_append(if_not_exists(#audioInfo, :empty_list), :new_audio_info)',
      ExpressionAttributeNames: {
        '#audioInfo': 'audioInfo',
      },
      ExpressionAttributeValues: {
        ':new_audio_info': [ { link: s3Link, title: audioTitle } ],
        ':empty_list': []
      },
      ReturnValues: 'ALL_NEW', // Adjust this based on your needs
    };
    dynamodb.update(secondaryParams, (err, data) => {
      setSuccessMessage("Files uploaded successfully.");
      if (err) {
        console.error("Error saving metadata to DynamoDB: ", err);
      }
    });

  };

  

  const audioTagsRef = useRef(null);

  const handleAudioTagsChange = (e, index) => {
    const newTags = e.target.value
      .split(/[,\n]/)
      .map((tag) => tag.trim())
      .filter((tag) => tag);
  
    setEnteredTags((prevTags) => {
      const updatedTags = [...prevTags];
      updatedTags[index] = newTags;
      return updatedTags;
    });
  
    const allAudioTags = enteredTags.flat().join(', '); // Combine tags from all indexes
    setAudioTags(allAudioTags);
  };
  

  const resetForm = () => {
    setFiles([]);
    setAudioTitles([]);
    setDroppedFiles([]);
    setAudioTags([]);
    setProgress(0);
    // setSuccessMessage('');
    setEnteredTags([]);
    setIsFileInputVisible(false);
    setIsFormVisible(true);
    setCurrentFileIndex(0);
    setTotalFiles(0); // Reset the total files count
    setFormSubmitted('');
    setFilesPresent(false);
    setTagsForAllFiles('');
  };


  const handleSubmit = async (values, actions) => {
    // Handle form submission if needed
    actions.setSubmitting(false);
  
    // Handle form submission if needed
    await uploadFile();
  
    // Reset the form after submission
    resetForm();
  };
  
 
useEffect(() => {
  // Check if the progress is 100% and the form is currently visible
  if (progress === 100 && isFormVisible) {
    setIsFormVisible(false);
  }
}, [progress, isFormVisible]);


function getTotalUploadedSize() {
  const uploadedSize = files
    .slice(0, currentFileIndex + 1)
    .reduce((totalSize, file) => totalSize + file.size, 0);

  // Convert bytes to megabytes
  const totalUploadedMB = (uploadedSize / (1024 * 1024)).toFixed(2);

  return `${totalUploadedMB} MB`;
}

const applyTagsToAllFiles = (tags, helpers) => {
  // Update the audioTags state for all files
  const updatedTags = new Array(files.length).fill(tags);
  setAudioTags(updatedTags);

  // Update the Formik field values for each file
  for (let i = 0; i < files.length; i++) {
    helpers.setFieldValue(`audioTag${i + 1}`, tags);
  }
};



return (
  <Formik
    initialValues={initialValues}
    validate={validate}
    onSubmit={handleSubmit}
  >
    {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
      <Form>
        <div className="title-page">
          <h1><span>ADD AUDIO</span></h1>
        </div>

        <div className="app-block">
          <p>This is where you add your audios!</p>
          <div className="successMessage">{successMessage}</div>
          <p></p>

          {isFileInputVisible ? (
            <div
              className="playlist-form"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <label htmlFor="file">Select Files: You can select or drag and drop multiple audios at once.</label>
        

              <input type="file" id="file"  onChange={handleFileChange} multiple accept=".aac, .mp3, .ogg, .opus, .wav"/>
              {filesPresent && (<> 
                            <label htmlFor="tagsForAllFiles">Apply tags to all audios at once:</label>
                           
                                           
    <input
      type="text"
      id="tagsForAllFiles"
      name="tagsForAllFiles"
      placeholder="Enter tags for all files"
      value={tagsForAllFiles}
      onChange={handleTagsForAllFilesChange}
    />
                      <p>This will overwrite any previous existing tag you have already entered for any audio.</p>       
    <button type="button" onClick={() => applyTagsToAllFiles(tagsForAllFiles)}>
      Apply Tags to ALL
    </button>
    <p>Tags and file names are used to create curated playlists. For example: Alexa, tell My Audio to play 'SUMMER'. 
      This will find all tags and file names with the word 'SUMMER' and create a curated playlist.</p>
      <p>Audio titles and tags are UNCHANGEABLE. Delete and re-create if you prefer other titles and tags.</p>
    </>
    )}



              {files.map((file, index) => (
  <div key={index}>
    <div className="filedata-container">
    <label htmlFor={`audioTitle${index + 1}`}>Audio Title {index + 1}:</label>
    <Field
      type="text"
      id={`audioTitle${index + 1}`}
      name={`audioTitle${index + 1}`}
      placeholder={`Enter audio title ${index + 1}`}
      value={audioTitles[index]}
      onChange={(e) => handleAudioTitleChange(e, index)}
      required
                  />

<label htmlFor={`audioTag${index + 1}`}>Audio Tags {index + 1}:</label>
    <Field
      type="text"
      id={`audioTag${index + 1}`}
      name={`audioTag${index + 1}`}
      placeholder={`Enter audio tag for: ${audioTitles[index]}`}
      value={audioTags[index]}
      onChange={(e) => handleAudioTagChange(e, index)}
      required
                  />
                </div>
                </div>
              ))}
{filesPresent && (
              <button type="submit">Upload</button>
)}
              {formSubmitted && files.length > 0 && (
  <div className="FileProgress">
    {`File ${currentFileIndex + 1} of ${totalFiles} uploaded`}
    <br />
    {`Total Uploaded: ${getTotalUploadedSize()}`}
  </div>
)}
{formSubmitted && (
              <div className="ProgressBarContainer">
                <div className="ProgressBar" style={{ width: `${progress}%` }}>
                  {progress}%
                </div>
              </div>
)}
              {droppedFiles.length > 0 && (
                <div>
                  <p>Files Dropped:</p>
                  <ul>
                    {droppedFiles.map((file, index) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <button className="AddAudioButton" onClick={toggleFileInput}>Add Audio</button>
          )}
        </div>
      </Form>
    )}
  </Formik>
);
}
