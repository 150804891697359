import { useEffect, useState } from 'react';
import { DynamoDB } from 'aws-sdk'; // Import DynamoDB from the correct location

// Initialize DynamoDB
const dynamodb = new DynamoDB.DocumentClient({
  region: 'us-east-1',
  // Replace these with your own AWS credentials
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export const RetrieveUsers = () => {
  const [items, setItems] = useState([]);

    const storedUser = localStorage.getItem('user');
    const userObject = JSON.parse(storedUser);
    const email = userObject.name;

    const storedPlaylistID = localStorage.getItem('playlistID'); // Retrieve the value from localStorage
    const playlistID = JSON.parse(storedPlaylistID); // Parse it as JSON


    const fetchItemsByUserEmail = async () => {
      try {
        const params = {
          TableName: 'MAP-sharedList', // Replace with your DynamoDB table name
          IndexName: 'playlistID-index', // Replace with the name of your composite GSI
          KeyConditionExpression: 'playlistID = :playlist',
          ExpressionAttributeValues: {
            ':playlist': playlistID,
          },
        };

        const results = await dynamodb.query(params).promise();

        // Filter out items where sharedUsers match the email
        const filteredItems = results.Items.filter((item) =>
          item.sharedUsers !== email
        );

        setItems(filteredItems);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    useEffect(() => {
        fetchItemsByUserEmail();
      }, []); // Make sure to include the dependencies, e.g., email and playlistID, here

  const handleDeleteItem = async (item) => {

    const storedPlaylistID = localStorage.getItem('playlistID'); // Retrieve the value from localStorage
    const playlistID = JSON.parse(storedPlaylistID); // Parse it as JSON

    const sharedListParams = {
        TableName: 'MAP-sharedList',
        IndexName: 'sharedUsers-playlistID-index',
        KeyConditionExpression: 'playlistID = :playlist AND sharedUsers = :email',
        ExpressionAttributeValues: {
            ':playlist': playlistID,
            ':email': item.sharedUsers,
        },
    };
  
    
  
    // Query all items matching the GSI in "MAP-sharedList"
    const sharedListResult = await dynamodb.query(sharedListParams).promise();
  
    if (sharedListResult.Items.length > 0) {
        for (const item of sharedListResult.Items) {
            const sharedListID = item.id;
  
            // Define the primary key for deletion in "MAP-sharedList"
            const deleteSharedListParams = {
                TableName: 'MAP-sharedList',
                Key: {
                    id: sharedListID,
                    playlistID: playlistID,
                },
            };
  
            await dynamodb.delete(deleteSharedListParams).promise();
            console.log('Record deleted from "MAP-sharedList":', deleteSharedListParams.Key);
            fetchItemsByUserEmail();
        }
    } else {
        console.log('No records matching the criteria found in "MAP-sharedList".');
    }
  
    console.log('All matching records deleted successfully.');

  };

  const handleApproveItem = async (item) => {
    // Implement your logic for handling approval
    console.log('Item approved:', item);

    const storedUser = localStorage.getItem('user');
    const userObject = JSON.parse(storedUser);
    const email = userObject.name;

    const storedPlaylistID = localStorage.getItem('playlistID'); // Retrieve the value from localStorage
    const playlistID = JSON.parse(storedPlaylistID); // Parse it as JSON

    const paramCheck = {
      TableName: 'MAP-sharedList',
      IndexName: 'sharedUsers-playlistID-index',
      KeyConditionExpression: 'sharedUsers = :email AND playlistID = :playlistid',
      ExpressionAttributeValues: {
        ':email': item.sharedUsers,
        ':playlistid': playlistID,
      },
    };
    
    try {
      const results = await dynamodb.query(paramCheck).promise();
    
      // Loop through the results and update the 'approved' attribute to 'yes'
      for (const item of results.Items) {
        const updateParams = {
          TableName: 'MAP-sharedList',
          Key: {
            'id': item.id, // Assuming 'id' is the primary key
            'playlistID': playlistID,
          },
          UpdateExpression: 'SET approved = :approved',
          ExpressionAttributeValues: {
            ':approved': 'yes',
          },
        };
    
        await dynamodb.update(updateParams).promise();
      }
    
      console.log('Items updated successfully.');
      fetchItemsByUserEmail();
    
    } catch (error) {
      console.error('Error updating items:', error);
    }

  };

  return (
    <>
      <div className="title-page">
        <h1>
          <span>USERS USING YOUR PLAYLIST</span>
        </h1>
      </div>
      <div className="app-block">
        <div>
          <table className="item-table">
            <thead>
              <tr>
                <th>Shared Users</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{item.sharedUsers}</td>
                  <td>
                    {item.approved === 'no' ? (
                      <><div className='approved-user'>
                        <div className='approved-text'>Approve user to use this playlist? </div>
                        <button className="approve-sharedUser" onClick={() => handleApproveItem(item)}>
                          Yes
                        </button>
                        <button className="reject-sharedUser" onClick={() => handleDeleteItem(item)}>
                          No
                        </button>
                        </div>
                      </>
                    ) : (
                      <button className="delete-sharedUser" onClick={() => handleDeleteItem(item)}>
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
