import React, { useState, useEffect, useContext, createContext } from 'react';
import { DynamoDB } from 'aws-sdk'; // Use the correct import for your AWS SDK version
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Initialize DynamoDB
const dynamodb = new DynamoDB.DocumentClient({
  region: 'us-east-1',
  // Replace these with your own AWS credentials
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

// Confirmation component
function DeleteConfirmation({ onCancel, onConfirm }) {
  return (
    <div className="button-confirmcancel">
      <p>Are you sure you want to remove this shared playlist? this will PERMANENTLY REMOVE it. You will not longer be able to use it. You must ask for a new request using 
        the Playlist ID.
      </p>
      <button onClick={onConfirm}>Confirm</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
}

export function RetrieveSharePlaylist() {
  const [items, setItems] = useState([]);

  const storedUser = localStorage.getItem('user');
  const userObject = JSON.parse(storedUser);
  const email = userObject.name;
  const [confirmation, setConfirmation] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);

  const [clickedLink, setClickedLink] = useState(false);



  const [editablePlaylist, setEditablePlaylist] = useState(null);
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const navigate = useNavigate();

  const handleEditClick = (playlistID) => {
    setEditablePlaylist(playlistID);
    setNewPlaylistName(items.find(item => item.playlistID === playlistID).playlistName);
  };

  const handleSaveClick = async (playlistID) => {
    const sanitizedPlaylistName = newPlaylistName.replace(/[^a-zA-Z0-9\s]/g, '');
    if (sanitizedPlaylistName.toLowerCase().includes('playlist')) {
      console.error('Error: Playlist name cannot contain the word "playlist".');
      // You can display an error message to the user if needed
      alert('Error: Playlist name cannot contain the word "playlist".')
      return;
    }

    await handlePlaylistNameChange(playlistID, sanitizedPlaylistName);
    setEditablePlaylist(null);
  };
  
  const handlePlaylistNameChange = async (playlistID, newName) => {
    try {
      // Update the playlist name in DynamoDB
      const paramsshared = {
        TableName: 'MAP-sharedList',
        IndexName: 'playlistID-index',
        KeyConditionExpression: 'playlistID = :playlist',
        FilterExpression: 'sharedUsers = :email', // Add this line to filter by sharedUsers
        ExpressionAttributeValues: {
          ':playlist': playlistID,
          ':email': email, // Replace 'email' with the actual value you want to match
        },
      };
  
      // Query items that match the condition
      dynamodb.query(paramsshared, (err, data) => {
        if (err) {
          console.error('Error querying items:', err);
        } else {
          // Loop through the query results
          data.Items.forEach((item) => {
            // Perform an update operation for each item
            const updateParams = {
              TableName: 'MAP-sharedList',
              Key: {
                id: item.id,
                playlistID: playlistID,
              },
              UpdateExpression: 'SET #playlistName = :newName, searchplaylistName = :lowercaseNewName',
              ExpressionAttributeNames: {
                '#playlistName': 'playlistName',
              },
              ExpressionAttributeValues: {
                ':newName': newName,
                ':lowercaseNewName': newName.toLowerCase(), // Convert newName to lowercase
              },
              ReturnValues: 'UPDATED_NEW',
            };
  
            dynamodb.update(updateParams, (updateErr, updateData) => {
              if (updateErr) {
                console.error('Error updating playlist name:', updateErr);
              } else {
                // Log the updated data
                console.log('Updated playlist data:', updateData);
  
                // Assuming 'items' and 'setItems' are defined elsewhere
                // Update the local state with the new playlist name
                const updatedItems = items.map((item) =>
                  item.playlistID === playlistID ? { ...item, playlistName: newName } : item
                );
                setItems(updatedItems);
              }
            });
          });
        }
      });
    } catch (error) {
      console.error('Error updating playlist name:', error);
    }
  
    setEditablePlaylist(null);
  };
  

  const handleEnterKey = (event, playlistID) => {
    if (event.key === 'Enter') {
      handleSaveClick(playlistID);
    }
  };


  const handleLinkClick = (playlistID) => {
    setClickedLink(true);
    navigate(`/playlist/shared/${playlistID}`);
  };


  const fetchItemsByUserEmail = async () => {
    try {
      const params = {
        TableName: 'MAP-sharedList',
        IndexName: 'sharedUsers-isShared-index',
        KeyConditionExpression: 'sharedUsers = :email AND isShared = :isShared',
        ExpressionAttributeValues: {
          ':email': email, // Define the :email attribute value
          ':isShared': 'Yes',
          ':approvedValue': 'yes',
        },
        FilterExpression: 'userEmail <> :email AND approved = :approvedValue',
      };


      const results = await dynamodb.query(params).promise();

      const items = results.Items.map((item) => {
        return {
          playlistID: item.playlistID,
          playlistName: item.playlistName,
          playlistShare: item.isShared,
          playlistDetails: item.playlistDetails,
          offset: item.offset,
          approved: item.approved,
          // Add more attributes as needed
        };
      });

      setItems(items);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  }

  useEffect(() => {
    fetchItemsByUserEmail();
  }, []);
  

  const handleDeleteClick = (playlistID) => {
    setDeletingItem(playlistID);
    setConfirmation(true);
  };

  const confirmDelete = async () => {
    if (deletingItem) {
      await deletePlaylist(deletingItem, email);
      setDeletingItem(null);
    }
    setConfirmation(false);
  };

  const cancelDelete = () => {
    setDeletingItem(null);
    setConfirmation(false);
  };

  const deletePlaylist = async (playlistID, userEmail) => {
    setConfirmation(
      `Are you sure you want to delete this shared playlist? This action will delete the shared list but will remain with the original owner.`
    );

    const sharedListParams = {
      TableName: 'MAP-sharedList',
      IndexName: 'sharedUsers-playlistID-index',
      KeyConditionExpression: 'playlistID = :playlist AND sharedUsers = :email',
      ExpressionAttributeValues: {
          ':playlist': playlistID,
          ':email': email,
      },
  };

  

  // Query all items matching the GSI in "MAP-sharedList"
  const sharedListResult = await dynamodb.query(sharedListParams).promise();

  if (sharedListResult.Items.length > 0) {
      for (const item of sharedListResult.Items) {
          const sharedListID = item.id;

          // Define the primary key for deletion in "MAP-sharedList"
          const deleteSharedListParams = {
              TableName: 'MAP-sharedList',
              Key: {
                  id: sharedListID,
                  playlistID: playlistID,
              },
          };

          await dynamodb.delete(deleteSharedListParams).promise();
          fetchItemsByUserEmail();
          console.log('Record deleted from "MAP-sharedList":', deleteSharedListParams.Key);
      }
  } else {
      console.log('No records matching the criteria found in "MAP-sharedList".');
  }

  console.log('All matching records deleted successfully.');
  setConfirmation(null); // Clear the confirmation message
};

    

  return (
<div className="app-block">
<div className="title-page"><h1><span>LIST OF SHARED PLAYLISTS</span>
        </h1>
        <p>Check out how playlists work using the <Link to='/how'>Alexa Commands</Link>. The easier the name, the easier it will work with Alexa.</p>
        </div>
  <div>
  {items.map((item, index) => (
          <div key={index} className="playlist-set">
            <div className="playlist-container">
                {editablePlaylist === item.playlistID ? (
                  <>
                  <div className='text-edit-container'>
                    <input
                      type="text"
                      value={newPlaylistName}
                      onChange={(e) => setNewPlaylistName(e.target.value)}
                      onKeyDown={(e) => handleEnterKey(e, item.playlistID)}
                    />
                    <span
                      className="edit-text"
                      onClick={() => handleSaveClick(item.playlistID)}
                    >
                      Save
                    </span>
                    </div>
                  </>
                ) : (
                  <>
                    {editablePlaylist === null ? (
                      <Link to={`/playlist/shared/${item.playlistID}`} className="playlist-title" data-label="Playlist">
                        {item.playlistName}
                      </Link>
                    ) : (
                      <div
                        className="playlist-title"
                        data-label="Playlist"
                        onClick={() => handleEditClick(item.playlistID)}
                      >
                        {item.playlistName}
                      </div>
                    )}
                    <span
                      className="edit-text"
                      onClick={() => handleEditClick(item.playlistID)}
                    >
                      {editablePlaylist === null ? 'Change Name' : 'Save'}
                    </span>
                  </>
                )}
              <a className="playlist-details" data-label="Playlist Details">
                {item.playlistDetails}
              </a>
          </div>
        <a className='playlist-alexa'>Alexa, tell My Audio to play "{item.playlistName.toUpperCase()}" Playlist</a>
        <a className="playlist-id" data-label="Unique ID">{item.playlistID}</a>
        <div className="playlist-tags" data-label="Public Shared">
            {item.playlistShare.split(',').map((tag, index) => (
              <span key={index} className="audio-tag">
                {tag.trim()}
              </span>
            ))}
          </div>
          <a className="playlist-play_mode" data-label="Play Mode"><b>It will {item.offset.toUpperCase()}</b> every audio in this playlist. Only owner can can change this setting</a>
          <button className="delete-button" onClick={() => handleDeleteClick(item.playlistID)}>
                Remove
              </button> {confirmation && deletingItem === item.playlistID && (
                <DeleteConfirmation onCancel={cancelDelete} onConfirm={confirmDelete} />
              )}
      </div>
    ))}
  </div>
</div>





  );
}