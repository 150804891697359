export const NotFound = () => {


    return (
        <>
        <div className="app-block">
<div className="title-page"><h1><span>OOPS (NOT FOUND)</span>)
     <p>Sorry, this page does not exist!</p>
       </h1>
       </div>
       </div>
       </>
    )
}