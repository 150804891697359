import { Link } from 'react-router-dom';

export const Home = () => {

     return (
        <>
          <div className="title-page"><h1><span>WELCOME TO </span> MY AUDIO APP</h1>
          </div>  
    <section className="key-features">
        <h2>Introducing My Audio App: Your Playlists, Your Audios!</h2>

        <div className="alexarowphotos">
  <div className="alexacolumnphotos">
    <img src="https://th.bing.com/th/id/OIP.pZuD-dQcBwqxDhpIlBwjLAHaEe?pid=ImgDet&rs=1" alt="Alexa" style={{ width: '200px' }} />
  </div>
  <div className="alexacolumnphotos">
    <img src="https://mindseye-communications.com/wp-content/uploads/2018/10/Alexa-image.png" alt="Alexa" style={{ width: '200px' }} />
  </div>
  <div className="alexacolumnphotos">
    <img src="https://www.smarthomeexplained.com/wp-content/uploads/2022/09/Echo-Charcoal-Side-1.png" alt="Alexa" style={{ width: '200px' }} />
  </div>
</div>
        <ul>
            <li>
            <span className="list-icon">1</span>
                <h3>CUSTOMIZED PLAYLISTS</h3>
                <p>Have special audios or even some cool audio books? Meet MyAudioApp, you upload your own audios and customized your playlists.</p>
            </li>
            <li>
            <span className="list-icon">2</span>
                <h3>SHARE YOUR VIBES</h3>
                <p>MyAudioApp makes sharing your carefully curated playlists a breeze. Just provide your friends and family with a unique ID, and they can instantly groove to your musical selections.</p>
            </li>
            <li>
            <span className="list-icon">3</span>
                <h3>SEAMLESS CONTINUITY</h3>
                <p>No more searching for where you left off. The Alexa app remembers your listening progress, allowing you to seamlessly continue your music journey. You can also rewind, fast forward, or start over with ease. Even better, don't want that? 
                    then you can also select which playlists which continue where you left off and which ones should start from the beginning.</p>
            </li>
            <li>
            <span className="list-icon">4</span>
                <h3>MULTI-DEVICE HARMONY</h3>
                <p>MyAudioApp effortlessly syncs your music across all Alexa-enabled devices. Transition between speakers without missing a single beat, ensuring your audio experience is consistently seamless.</p>
            </li>
            <li>
            <span className="list-icon">5</span>
                <h3>DIVERSE AUDIO FORMATS</h3>
                <p>MyAudioApp supports various audio file types, including aac, mp3, ogg, opus, and wav.</p>
            </li>
            <li>
            <span className="list-icon">6</span>
                <h3>VOICE CONTROLLED CONVENIENCE</h3>
                <p>MyAudioApp is hands-free, allowing you to control your music effortlessly with just your voice. Enjoy your music without interruptions or distractions.</p>
            </li>
            <li>
            <span className="list-icon">7</span>
                <h3>SETTING IT UP IS EASY</h3>
                <p>Go to your Alexa app in your phone, go to skills, find My Audio App skill, activate it, link it to your account, and that's it!</p>
            </li>
        </ul>
        <section className="app-blockbottom">
        <h2>Experience MyAudioApp Today!</h2>
        <Link to={"/login"} className="cta-button">Get Started</Link>
    </section>
    </section>
    

    </>
            
     )
}