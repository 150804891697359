import React, { useState, useEffect, useContext, createContext } from 'react';
import { DynamoDB, S3 } from 'aws-sdk'; // Use the correct import for your AWS SDK version
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDynamoDBUpdated } from '../../../Store';


// Initialize DynamoDB
const dynamodb = new DynamoDB.DocumentClient({
  region: 'us-east-1',
  // Replace these with your own AWS credentials
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});


const s3 = new S3({
  region: 'us-east-1',
  // Replace these with your own AWS credentials
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});




// Confirmation component
function DeleteConfirmation({ onCancel, onConfirm, processing }) {
  const [title, setTitle] = useState('Are you sure you want to delete this playlist? This will PERMANENTLY DELETE all of the audios in this playlist.');

  const handleConfirm = async () => {
    setTitle('Processing...');
    await onConfirm(); // Assuming onConfirm is an asynchronous function
    setTitle('Are you sure you want to delete this playlist? This will PERMANENTLY DELETE all of the audios in this playlist.');
  };

  return (
    <div className="button-confirmcancel">
      <p>{title}</p>
      <button onClick={handleConfirm} disabled={processing}>
        {processing ? 'Processing...' : 'Confirm'}
      </button>
      <button onClick={onCancel} disabled={processing}>
        Cancel
      </button>
    </div>
  );
}

const storedUser = localStorage.getItem('user');
const userObject = storedUser ? JSON.parse(storedUser) : null;
const email = userObject ? userObject.name : null;




export function RetrievePlaylists() {
  const [items, setItems] = useState([]);
  const [confirmation, setConfirmation] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);
  const navigate = useNavigate();
  const [isToggleOn, setIsToggleOn] = useState(null);
  const [toggleStates, setToggleStates] = useState([]);

  const storedUser = localStorage.getItem('user');
  const userObject = storedUser ? JSON.parse(storedUser) : null;
  const email = userObject ? userObject.name : null;

  const [clickedLink, setClickedLink] = useState(false);

  const [editablePlaylist, setEditablePlaylist] = useState(null);
  const [newPlaylistName, setNewPlaylistName] = useState('');

  const isDynamoDBUpdated = useSelector(state => state.dynamoDBUpdated);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data or perform any actions you want
        await fetchItemsByUserEmail();
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    if (isDynamoDBUpdated) {
      // Run fetchData when isDynamoDBUpdated becomes true
      fetchData();

      // Reset the state in the Redux store
      dispatch(setDynamoDBUpdated(false));
    }
  }, [isDynamoDBUpdated, dispatch]);

  const handleEditClick = (playlistID) => {
    setEditablePlaylist(playlistID);
    setNewPlaylistName(items.find(item => item.playlistID === playlistID).playlistName);
  };

  const handleSaveClick = async (playlistID) => {
    const sanitizedPlaylistName = newPlaylistName.replace(/[^a-zA-Z0-9\s]/g, '');
    if (sanitizedPlaylistName.toLowerCase().includes('playlist')) {
      console.error('Error: Playlist name cannot contain the word "playlist".');
      // You can display an error message to the user if needed
      alert('Error: Playlist name cannot contain the word "playlist".')
      return;
    }

    await handlePlaylistNameChange(playlistID, sanitizedPlaylistName);
    setEditablePlaylist(null);
  };

  const handlePlaylistNameChange = async (playlistID, newName) => {
    try {
      // Update the playlist name in DynamoDB (first table)
      const params = {
        TableName: 'MAP-playlist',
        Key: {
          playlistID: playlistID,
          userEmail: email, // Assuming email is in scope
        },
        UpdateExpression: 'SET #playlistName = :newName',
        ExpressionAttributeNames: {
          '#playlistName': 'playlistName',
        },
        ExpressionAttributeValues: {
          ':newName': newName,
        },
        ReturnValues: 'UPDATED_NEW',
      };
  
      const result = await dynamodb.update(params).promise();
  
      // Log the updated data
      console.log('Updated playlist data:', result);
  
      // Update the local state with the new playlist name
      const updatedItems = items.map((item) =>
        item.playlistID === playlistID ? { ...item, playlistName: newName } : item
      );
      setItems(updatedItems);
  
      // Update the playlist name in the shared list table
      const paramsshared = {
        TableName: 'MAP-sharedList',
        IndexName: 'playlistID-index',
        KeyConditionExpression: 'playlistID = :playlist',
        FilterExpression: 'sharedUsers = :email',
        ExpressionAttributeValues: {
          ':playlist': playlistID,
          ':email': email,
        },
      };
  
      // Query items that match the condition
      const queryResult = await dynamodb.query(paramsshared).promise();
  
      // Loop through the query results
      const updatePromises = queryResult.Items.map(async (item) => {
        // Perform an update operation for each item in the shared list table
        const updateParams = {
          TableName: 'MAP-sharedList',
          Key: {
            id: item.id,
            playlistID: playlistID,
          },
          UpdateExpression: 'SET #playlistName = :newName, searchplaylistName = :lowercaseNewName',
          ExpressionAttributeNames: {
            '#playlistName': 'playlistName',
          },
          ExpressionAttributeValues: {
            ':newName': newName,
            ':lowercaseNewName': newName.toLowerCase(),
          },
          ReturnValues: 'UPDATED_NEW',
        };
  
        // Return the promise of the update operation
        return dynamodb.update(updateParams).promise();
      });
  
      // Wait for all the update promises to resolve
      const updateResults = await Promise.all(updatePromises);
  
      // Log the updated data
      updateResults.forEach((updateData, index) => {
        console.log(`Updated playlist data in sharedList (${index + 1}):`, updateData);
      });
    } catch (error) {
      console.error('Error updating playlist name:', error);
    }
  
    setEditablePlaylist(null);
  };
  

  const handleEnterKey = (event, playlistID) => {
    if (event.key === 'Enter') {
      handleSaveClick(playlistID);
    }
  };


  const handleLinkClick = (playlistID) => {
    setClickedLink(true);
    navigate(`/playlist/${playlistID}`);
  };

  const fetchItemsByUserEmail = async () => {
    try {
      const params = {
        TableName: 'MAP-playlist', // Replace with your DynamoDB table name
        IndexName: 'userEmail-index',
        KeyConditionExpression: 'userEmail = :email',
        ExpressionAttributeValues: {
          ':email': email,
        },
      };

      const results = await dynamodb.query(params).promise();

      const items = results.Items.map((item) => {
        return {
          playlistID: item.playlistID,
          playlistName: item.playlistName,
          playlistShare: item.shared,
          playlistDetails: item.playlistDetails,
          offset: item.offset,
          // Add more attributes as needed
        };
      });
      setItems(items);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  }

  useEffect(() => {
    // Initialize toggle states when items change
    const initialToggleStates = items.map((item) => item.offset === 'restart');
    setToggleStates(initialToggleStates);
  }, [items]);

  

  useEffect(() => {
    fetchItemsByUserEmail();
  }, []);

  const handleDeleteClick = (playlistID) => {
    setDeletingItem(playlistID);
    setConfirmation(true);
  };

  const confirmDelete = async () => {
    if (deletingItem) {
      await deletePlaylist(deletingItem, email);
      setDeletingItem(null);
    }
    setConfirmation(false);
  };

  const cancelDelete = () => {
    setDeletingItem(null);
    setConfirmation(false);
  };

  

  const deletePlaylist = async (playlistID, userEmail) => {
    setConfirmation(
      `Are you sure you want to delete this playlist? This action will delete the record permanently and all of its audio.`
    );

    // You can also provide an option to confirm or cancel the deletion.


        // Delete records from the "MAP-audios" table
        const audioParams = {
            TableName: 'MAP-audios',
            IndexName: 'playlistID-index',
            KeyConditionExpression: 'playlistID = :playlist',
            ExpressionAttributeValues: {
                ':playlist': playlistID,
            },
            ProjectionExpression: 'audioID, userEmail', // You need these attributes for deletion
        };

        // Query all items matching the GSI in "MAP-audios"
        const audioResult = await dynamodb.query(audioParams).promise();

        if (audioResult.Items.length > 0) {
            for (const item of audioResult.Items) {
                const audioID = item.audioID;

                // Define the primary key for deletion in "MAP-audios"
                const deleteAudioParams = {
                    TableName: 'MAP-audios',
                    Key: {
                        audioID: audioID,
                        userEmail: email, // Use the same userEmail
                    },
                };

                await dynamodb.delete(deleteAudioParams).promise();
                console.log('Record deleted from "MAP-audios":', deleteAudioParams.Key);
                // Delete the corresponding S3 object
                const s3ObjectKey = audioID.toString(); // Update this to match your S3 object naming convention
                const s3DeleteParams = {
                    Bucket: 'myaudioappbucket',
                    Key: s3ObjectKey,
                };

                try {
                    await s3.deleteObject(s3DeleteParams).promise();
                    console.log(`Deleted S3 object: ${s3ObjectKey}`);
                    console.log(audioID);
                } catch (error) {
                    console.error(`Error deleting S3 object: ${s3ObjectKey}`, error);
                }
            }
        } else {
            console.log('No records matching the criteria found in "MAP-audios".');
        }

        // Delete the record from the "MAP-playlist" table
        const playlistParams = {
            TableName: 'MAP-playlist',
            Key: {
                playlistID: playlistID,
                userEmail: email, // Use the same userEmail
            },
        };

        await dynamodb.delete(playlistParams).promise();
        console.log('Record deleted from "MAP-playlist":', playlistParams.Key);

        // Delete records from the "MAP-sharedList" table
        const sharedListParams = {
            TableName: 'MAP-sharedList',
            IndexName: 'playlistID-index',
            KeyConditionExpression: 'playlistID = :playlist',
            ExpressionAttributeValues: {
                ':playlist': playlistID,
            },
        };

        

        // Query all items matching the GSI in "MAP-sharedList"
        const sharedListResult = await dynamodb.query(sharedListParams).promise();

        if (sharedListResult.Items.length > 0) {
            for (const item of sharedListResult.Items) {
                const sharedListID = item.id;

                // Define the primary key for deletion in "MAP-sharedList"
                const deleteSharedListParams = {
                    TableName: 'MAP-sharedList',
                    Key: {
                        id: sharedListID,
                        playlistID: playlistID,
                    },
                };

                await dynamodb.delete(deleteSharedListParams).promise();
                fetchItemsByUserEmail();
                console.log('Record deleted from "MAP-sharedList":', deleteSharedListParams.Key);
            }
        } else {
            console.log('No records matching the criteria found in "MAP-sharedList".');
        }

        console.log('All matching records deleted successfully.');
        setConfirmation(null); // Clear the confirmation message
  };

  const handleToggleClick = (offset, playlistID, index, email) => {
        
        // Toggle the state to switch the button's appearance
     // Add your code here to run based on the toggle status
    if (!toggleStates[index]) {
      // Toggle is on, so run code for restart
      const storedUser = localStorage.getItem('user');
      const userObject = JSON.parse(storedUser);
      const email = userObject.name;
      const params = {
        TableName: 'MAP-playlist',
        Key: {
          playlistID: playlistID,
          userEmail: email,
        },
        UpdateExpression: 'SET #offset = :newOffset',
        ExpressionAttributeNames: {
          '#offset': 'offset',
        },
        ExpressionAttributeValues: {
          ':newOffset': 'restart', // New value for the 'offset' attribute
        },
        ReturnValues: 'UPDATED_NEW',
      };
      
      dynamodb.update(params, (err, data) => {
        if (err) {
          console.error("Error updating metadata in DynamoDB: ", err);
        } else {
          console.log("Item updated successfully:", data);
        }
      });

      // now this is for the shared playlist
      const paramsshared = {
        TableName: 'MAP-sharedList',
        IndexName: 'playlistID-index',
        KeyConditionExpression: 'playlistID = :playlist',
        ExpressionAttributeValues: {
          ':playlist': playlistID,
        },
      };
      
      // Query items that match the condition
      dynamodb.query(paramsshared, (err, data) => {
        if (err) {
          console.error('Error querying items:', err);
        } else {
          // Loop through the query results
          data.Items.forEach((item) => {
            // Perform an update operation for each item
            const updateParams = {
              TableName: 'MAP-sharedList',
              Key: {
                id: item.id,
                playlistID: playlistID,
              },
              UpdateExpression: 'SET #offset = :newOffset',
        ExpressionAttributeNames: {
          '#offset': 'offset',
        },
        ExpressionAttributeValues: {
          ':newOffset': 'restart', // New value for the 'offset' attribute
        },
        ReturnValues: 'UPDATED_NEW',
      };
      
            dynamodb.update(updateParams, (updateErr, updateData) => {
              if (updateErr) {
                console.error('Error updating item:', updateErr);
              } else {
                console.log('Item updated:', updateData);
              }
            });
          });
        }
      });




    } else {
      // Toggle is off, so run code for continue
      const storedUser = localStorage.getItem('user');
      const userObject = JSON.parse(storedUser);
      const email = userObject.name;
      const params = {
        TableName: 'MAP-playlist',
        Key: {
          playlistID: playlistID,
          userEmail: email,
        },
        UpdateExpression: 'SET #offset = :newOffset',
        ExpressionAttributeNames: {
          '#offset': 'offset',
        },
        ExpressionAttributeValues: {
          ':newOffset': 'continue', // New value for the 'offset' attribute
        },
        ReturnValues: 'UPDATED_NEW',
      };
      
      dynamodb.update(params, (err, data) => {
        if (err) {
          console.error("Error updating metadata in DynamoDB: ", err);
        } else {
          console.log("Item updated successfully:", data);
        }
      });
      // You can replace the console.log with your actual code logi

      // now this is for the shared playlist
      const paramsshared = {
        TableName: 'MAP-sharedList',
        IndexName: 'playlistID-index',
        KeyConditionExpression: 'playlistID = :playlist',
        ExpressionAttributeValues: {
          ':playlist': playlistID,
        },
      };
      
      // Query items that match the condition
      dynamodb.query(paramsshared, (err, data) => {
        if (err) {
          console.error('Error querying items:', err);
        } else {
          // Loop through the query results
          data.Items.forEach((item) => {
            // Perform an update operation for each item
            const updateParams = {
              TableName: 'MAP-sharedList',
              Key: {
                id: item.id,
                playlistID: playlistID,
              },
              UpdateExpression: 'SET #offset = :newOffset',
        ExpressionAttributeNames: {
          '#offset': 'offset',
        },
        ExpressionAttributeValues: {
          ':newOffset': 'continue', // New value for the 'offset' attribute
        },
        ReturnValues: 'UPDATED_NEW',
      };
      
            dynamodb.update(updateParams, (updateErr, updateData) => {
              if (updateErr) {
                console.error('Error updating item:', updateErr);
              } else {
                console.log('Item updated:', updateData);
              }
            });
          });
        }
      });
    }

    const newToggleStates = [...toggleStates];
    newToggleStates[index] = !newToggleStates[index];
    setToggleStates(newToggleStates);
  };


  return (
<div className="app-block">  
<div className="title-page"><h1><span>LIST OF PLAYLISTS</span>
        </h1>
        <p>Check out how playlists work using the <Link to='/how'>Alexa Commands</Link>. The easier the playlistname, the easier it will work with Alexa.</p>
        </div>
  <div>
  {items.map((item, index) => (
          <div key={index} className="playlist-set">
            <div className="playlist-container">
                {editablePlaylist === item.playlistID ? (
                  <>
                  <div className='text-edit-container'>
                    <input
                      type="text"
                      value={newPlaylistName}
                      onChange={(e) => setNewPlaylistName(e.target.value)}
                      onKeyDown={(e) => handleEnterKey(e, item.playlistID)}
                    />
                    <span
                      className="edit-text"
                      onClick={() => handleSaveClick(item.playlistID)}
                    >
                      Save
                    </span>
                    </div>
                  </>
                ) : (
                  <>
                    {editablePlaylist === null ? (
                      <Link to={`/playlist/${item.playlistID}`} className="playlist-title" data-label="Playlist">
                        {item.playlistName}
                      </Link>
                    ) : (
                      <div
                        className="playlist-title"
                        data-label="Playlist"
                        onClick={() => handleEditClick(item.playlistID)}
                      >
                        {item.playlistName}
                      </div>
                    )}
                    <span
                      className="edit-text"
                      onClick={() => handleEditClick(item.playlistID)}
                    >
                      {editablePlaylist === null ? 'Change Name' : 'Save'}
                    </span>
                  </>
                )}
              <a className="playlist-details" data-label="Playlist Details">
                {item.playlistDetails}
              </a>
          </div>
          <a className='playlist-alexa'>Alexa, tell My Audio to play "{item.playlistName.toUpperCase()}" Playlist</a>
          <a className="playlist-id" data-label="Unique ID">
            {item.playlistID}
          </a>
          <div className="playlist-tags" data-label="Public Shared">
            {item.playlistShare.split(',').map((tag, index) => (
              <span key={index} className="audio-tag">
                {tag.trim()}
              </span>
            ))}
          </div>
          <div className="toggle-container">
              <button
                className={`toggle-button ${toggleStates[index] ? 'on' : 'off'}`} // Step 3 (CSS classes)
                onClick={() => handleToggleClick(item.offset, item.playlistID, index)}
              >
                {toggleStates[index] ? 'Restart' : 'Continue'}
              </button>
            </div>
            {toggleStates[index] ? (
            <div className="text-under-toggle">This will restart every audio in this playlist. Default for music or shorter audio files.</div>
          ) : (
            <div className="text-under-toggle">This will continue the audio where you left off. Recommended for audiobooks and longer audio file types
            that you will like to continue where you left off.</div>
          )}
          <button className="delete-button" onClick={() => handleDeleteClick(item.playlistID)}>
                Permanently Delete
              </button> {confirmation && deletingItem === item.playlistID && (
                <DeleteConfirmation onCancel={cancelDelete} onConfirm={confirmDelete} />
              )}
          </div>
        ))}
      </div>
    </div>
  );
}