import { About } from "../pages/About"
import { Account } from "../pages/Account"
import { Home } from "../pages/Home"
import { Login } from "../pages/Login"
import { Playlists } from "../pages/Playlists"
import { How } from "../pages/How"
import { RetrievePlaylists } from "../pages/components-pages/retrieveplaylists"
import { PlaylistDetails } from "../pages/components-pages/playlistdetails"
import { PlaylistSharedDetails } from "../pages/components-pages/playlistshareddetails"
import { ClickHandler } from "./ClickHandler"; // Import your custom route handler
import { NotFound } from "../pages/components-pages/notfound"
import { Terms } from "../pages/Terms"
import { Privacy } from "../pages/Privacy"
import { PricingPage } from "../pages/components-pages/PricingPage"
import { Alexa } from "../pages/Alexa"


export const nav = [
     { path:     "/",         name: "HOME",        element: <Home />,       isMenu: false, isPrivate: false  },
     { path:     "/about",    name: "ABOUT",       element: <About />,      isMenu: true,     isPrivate: false  },
     { path:     "/login",    name: "LOGIN",       element: <Login />,      isMenu: false,    isPrivate: false  },
     { path:     "/playlists",  name: "PLAYLISTS",     element: <Playlists />,    isMenu: true,     isPrivate: true  },
     { path:     "/account",  name: "ACCOUNT",     element: <Account />,    isMenu: true,     isPrivate: true  },
     { path:     "/how",  name: "HOW",     element: <How />,    isMenu: true,     isPrivate: false  },
     { path:     "/playlist/:id",  name: "playlist",     element:  <PlaylistDetails />,    isMenu: false,     isPrivate: true  },
     { path:     "/playlist/shared/:id",  name: "playlist-shared",     element: <PlaylistSharedDetails />,    isMenu: false,     isPrivate: true  },
     { path:     "/notfound",  name: "notfound",     element: <NotFound />,    isMenu: false,     isPrivate: false  },
     { path:     "/terms",  name: "terms",     element: <Terms />,    isMenu: false,     isPrivate: false  },
     { path:     "/privacy",  name: "privacy",     element: <Privacy />,    isMenu: false,     isPrivate: false  },
     { path:     "/pricing",  name: "PRICING",     element: <PricingPage />,    isMenu: true,     isPrivate: false  },
     { path:     "/alexa",  name: "ALEXA",     element: <Alexa />,    isMenu: true,     isPrivate: false  },

];