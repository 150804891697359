import React, { Component, useEffect, useState } from 'react';
import { AddAudio } from './addaudio';
import { RetrieveAudios } from './retrieveaudios';
import { DynamoDB, S3 } from 'aws-sdk'; // Use the correct import for your AWS SDK version
import { useParams } from 'react-router-dom'; // Import for React Router
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook for redirection
import { RetrieveUsers } from './retrieveusers';

// Initialize DynamoDB
const dynamodb = new DynamoDB.DocumentClient({
  region: 'us-east-1',
  // Replace these with your own AWS credentials
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export const PlaylistDetails = () => {
  const storedUser = localStorage.getItem('user');
  const userObject = JSON.parse(storedUser);
  const email = userObject.name;

  const { id } = useParams();
  const [playlistID, setPlaylistID] = useState(id)
  localStorage.setItem("playlistID", JSON.stringify(playlistID)); // Save to localStorage

  const [items, setItems] = useState([]);
  const navigate = useNavigate(); // Use useNavigate for redirection

  const fetchItemsByUserEmail = async () => {
    try {
      const params = {
        TableName: 'MAP-sharedList',
        IndexName: 'playlistID-index',
        KeyConditionExpression: 'playlistID = :playlist',
        ExpressionAttributeValues: {
          ':playlist': id,
          ':email': email,
        },
        FilterExpression: 'userEmail = :email',
      };

      const results = await dynamodb.query(params).promise();

      const items = results.Items.map((item) => {
        return {
          playlistID: item.playlistID,
          // Add more attributes as needed
        };
      });

      setItems(items);

      // Check if items were found, and if not, redirect to another page
      if (items.length === 0) {
        navigate('/notfound'); // Replace '/redirect-page' with the actual URL
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  useEffect(() => {
    fetchItemsByUserEmail();
  }, []); // This runs the fetchItemsByUserEmail function when the component mounts

  return (
    <>
      <div className="title-page">
        <h1>
          <span>INFORMATION ABOUT THIS PLAYLIST</span>
        </h1>
        </div>
        <div className="app-block">
      
<div className="playlistdetails-container">
  <div className="playlistdetails-column">
    <RetrieveUsers />
  </div>
  <div className="playlistdetails-column">
    <RetrieveAudios />
  </div>
  <div className="playlistdetails-column add-audio">
    <AddAudio />
  </div>
</div>
</div>
    </>
  );
};
