import React, { useEffect, useState } from 'react';

export const PricingPage = () => {
  const storedUser = localStorage.getItem('user');
  const userObject = storedUser ? JSON.parse(storedUser) : null;
  const email = userObject ? userObject.name : null;


  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
    <div className="app-block">
<div className="title-page"><h1><span>SUBSCRIPTION PAGE</span>)
 <p>Select one of the options below to get get more features.</p>
   </h1>
   </div>
    <stripe-pricing-table
      pricing-table-id="prctbl_1O79DLFrfuuBIT4LsPdKvOpD"
      publishable-key="pk_test_51O22HdFrfuuBIT4LaEEMZHSnSwtwBS6vSDmP4GGlwMWPjgNQIqwdp2aocrNC6CVxY5JIk5J4r0zF1CRzSADPq4OI00tppqtyXC"
      customer-email= {email}
    >
    </stripe-pricing-table>
    </div>
    </>
  );
}
