import react, { Component } from 'react';
import { Link } from 'react-router-dom';


export const Alexa = () => {

    return (       
      <>
  <>
          <div className="title-page"><h1><span>HOW TO INSTALL ALEXA </span></h1>
          </div>  
    <section className="key-features">
        <h2>HOW TO GET STARTED IN 5 EASY STEPS</h2>

        <div className="alexarowphotos">
  <div className="alexacolumnphotos">
    <img src="https://i.pinimg.com/originals/94/0c/1e/940c1ec087f9c11748ff8c28bf06c91e.png" alt="Alexa" style={{ width: '400px', height: '300px'}} />
  </div>
  <div className="alexacolumnphotos">
    <img src="https://www.pngkey.com/png/full/70-704990_amazon-alexa-logo-png-clip-freeuse-library-amazon.png" alt="Alexa" style={{ width: '400px' }} />
  </div>
  <div className="alexacolumnphotos">
    <img src="https://webanalytics.onlineimpactgroup.com/alexa-login.png" alt="Alexa" style={{ width: 'auto', height: '300px' }} />
  </div>
</div>
        <ul>
            <li>
            <span className="list-icon">1</span>
                <h3>OPEN ALEX APP IN YOUR PHONE</h3>
                <p>Find the skill "My Audio App" in your Alexa phone App.</p>
            </li>
            <li>
            <span className="list-icon">2</span>
                <h3>ALEXA SKILLS AND ACTIVATION</h3>
                <p>Go to Alexa Skills and find My Audio App. Active the skill in your Alexa App. Then activate the skill for your account.</p>
            </li>
            <li>
            <span className="list-icon">3</span>
                <h3>LINK YOUR ACCOUNT</h3>
                <p> You need to link the Alexa skill with your Amazon Account.</p>
            </li>
            <li>
            <span className="list-icon">4</span>
                <h3>USE THE SAME AMAZON ACCOUNT</h3>
                <p>You need to sign at https://wwww.myaudioapp.com and use the same Alexa Account mentioned in number 3.</p>
            </li>
            <li>
            <span className="list-icon">5</span>
                <h3>READY FOR ALL YOUR DEVICES</h3>
                <p>Once this is done, then you area ready to get started.</p>
            </li>
        </ul>
        <section className="app-blockbottom">
        <h2>CHECK OUT THE HOW IT WORKS</h2>
        <Link to={"/how"} className="cta-button">HOW IT WORKS</Link>
    </section>
    </section>
    

    </>
                 </>
              
    )
}